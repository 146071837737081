import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React, { useState } from "react";

import TranslateButton from "~shared/Button/TranslateButton";

export default function TextWithTranslateButton({
    text,
    i18n,
    propertyName,
    TextComponent = Box,
    textComponentStyle = {},
}) {
    const [translatableText, setTranslatableText] = useState(text);

    return (
        <Box>
            <TextComponent style={textComponentStyle}>
                {translatableText}
            </TextComponent>
            <TranslateButton
                text={translatableText}
                onTranslated={setTranslatableText}
                propertyName={propertyName}
                i18n={i18n}
            />
        </Box>
    );
}

TextWithTranslateButton.propTypes = {
    i18n: PropTypes.array,
    text: PropTypes.string,
    propertyName: PropTypes.string,
    TextComponent: PropTypes.elementType,
    textComponentStyle: PropTypes.object,
};
